<template>
  <div class="flex-1" @click.stop>
    <div class="flex items-center switch-content justify-between">
      <div v-if="$slots.default" :class="contentClass">
        <slot />
      </div>
      <div @mouseover="handleMouseOver" @mouseout="handleMouseOut">
        <label
          :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
          :for="`${name}-toggle-${modelValue ? 'on' : 'off'}`"
          data-cy="toggle-label"
          @click="handleToggle"
        >
          <div
            class="relative h-6 w-10 transition-all rounded"
            :class="{
              'bg-primary': modelValue,
              'bg-gray-300': !modelValue,
              'opacity-60': disabled
            }"
          >
            <div
              class="absolute top-1 left-1 rounded-sm h-4 w-4 bg-white transition-all transform"
              :class="{
                'translate-x-4': modelValue,
                'translate-x-0': !modelValue
              }"
            />
          </div>
        </label>
        <v-field
          class="hidden"
          :id="`${name}-toggle-on`"
          type="radio"
          :name="name"
          :value="true"
          :disabled="disabled"
          :role="role"
        />
        <v-field
          class="hidden"
          :id="`${name}-toggle-off`"
          data-cy="toggle"
          type="radio"
          :name="name"
          :value="false"
          :disabled="disabled"
          :role="role"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Field as VField } from "vee-validate";
import type { PropType } from "vue";

const emit = defineEmits<{
  (e: "toggleChanged", data: boolean, event: Event): void;
  (e: "update:modelValue", data: boolean): void;
  (e: "onMouseOver"): void;
  (e: "onMouseOut"): void;
}>();

const props = defineProps({
  name: {
    type: [String, Number],
    required: true
  },
  modelValue: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  role: {
    type: String,
    default: ""
  },
  contentClass: {
    type: [String, Object] as PropType<string | Record<string, boolean>>,
    default: ""
  }
});

const handleToggle = (e: Event) => {
  if (!props.disabled) {
    emit("toggleChanged", !props.modelValue, e);
    emit("update:modelValue", !props.modelValue);
  }
};
const handleMouseOver = () => {
  emit("onMouseOver");
};
const handleMouseOut = () => {
  emit("onMouseOut");
};
</script>
